* {
  margin: 0;
  /* font-family: "Lato"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

input[name="Dates"]::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #454545;
}
input[name="Dates"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #454545;
  opacity: 1;
}
input[name="Dates"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #454545;
  opacity: 1;
}
input[name="Dates"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #454545;
}
